html {
  background-color: #292929;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #B5B5B5;
}

html, body, #root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
 }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style: none; /* Remove default bullets */
}

ul li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #666; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 0.8em; /* Also needed for space (tweak if needed) */
  margin-left: -0.8em; /* Also needed for space (tweak if needed) */
}

ul { 
  padding: 0;
  margin: 0;
  padding-left: 0.8em;
}

h1,h2,h3,h4,h5,h6,p {margin: 0  }


a:link, a:visited, a:hover, a:active {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-size: 1.7rem;
}

p {line-height: 1.45em;}